import './App.css';
import Footer from './components/footer';
import Navbar from './components/navbar';
import Home from './pages/home';
import Projects from './pages/projects';

import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />}/>
          <Route path='projects' element={<Projects />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function Layout(){
  return(
    <div className='flex flex-col min-h-screen px-5 pt-10 pb-5 bg-background lg:px-10'>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
