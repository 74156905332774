import { Link } from "react-router-dom";

export default function Button({to, name}){
    return(
        <Link to={to} className='text-[15px] lg:text-base'>
            <button type="button" className='px-9 py-[.32rem] border-2 min-h-full border-mainBlue text-mainBlue rounded-[9px] max-h-[40px] hover:bg-mainBlue hover:text-gray-50 duration-150'>
                {name}
            </button>
        </Link>
    );
}