import { Link, useLocation } from "react-router-dom";
import Logo from "../images/Logo.svg";


export default function Navbar(){
    return(
        <nav className="flex w-full items-center justify-between mb-4 lg:mb-0">
            <div className="w-14 lg:w-20">
                <div className="w-[80%]">
                    <Link to={'/'}>
                        <img src={Logo}  alt="Logo from Alfonso Amaya, click on it to return to the main page"/>
                    </Link>
                </div>
            </div>
            <ul className="gap-x-5 hidden lg:flex">
                <ListItem name={"Projects"} to={'/projects'}/>
                <ListItem name={"Resume"} to={'/resume'}/>
                <ListItem name={"Contact"} to={'/contact'}/>
                <ListItem name={"About me"} to={'/about'}/>
            </ul>
            <svg className="w-8 h-8 lg:hidden" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" /></svg>
        </nav>
    );
}

function ListItem(props){
    const location = useLocation();
    return(
        <li className={`listItem relative duration-150 ${location.pathname === props.to ? "active text-accentGoldenrod" : "hover:after:bottom-[-20%] hover:text-accentGoldenrod hover:after:opacity-100"}`}>
            <Link to={props.to}>{props.name}</Link>
        </li>
    );
}