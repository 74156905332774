export const Project = ({imageURL, title, description, link, ...props}) => {
  return (
    <div className={`mx-auto max-w-[409px] col-span-3`}>
        <img src={imageURL} alt={title} className="lg:w-[409px] lg:h-[409px]"></img>
        <h2 className="text-center mt-10 mb-5 font-bold text-lg">{title}</h2>
        <p className="text-justify leading-relaxed mb-10">{description}</p>
        <a href={link} className='text-[15px] lg:text-base' target={"_blank"} rel={"noopener noreferrer"}>
            <button type="button" className='px-9 py-[.32rem] border-2 border-mainBlue text-mainBlue rounded-[9px] max-h-[40px] hover:bg-mainBlue hover:text-gray-50 duration-150'>
                See project
            </button>
        </a>
    </div>
  )
}
