import face from '../images/Face.svg';
import Button from '../components/button';

export default function Home(){
    return (
        <main className='flex-1 flex items-center'>
            <article className='flex flex-col items-start justify-center gap-y-8 lg:gap-x-24 lg:flex-row'>
                <section className="w-[60%] self-center lg:w-1/3">
                    <img src={face} alt='My vectorized face' className='mx-auto'/>
                </section>
                <section className='md:w-[80%] self-center lg:self-auto lg:w-1/3'>
                    <h1 className='coda text-2xl lg:text-3xl'>
                        <span className='coda text-mainBlue'>Electrical Engineering</span> Student and <span className='coda text-accentGoldenrod'>Web Designer</span>
                    </h1>
                    <p className='mt-3 self-start leading-relaxed text-[15px] w-full lg:text-base'>
                    Hi! I'm <strong>Alfonso Amaya</strong>, nice to see you here.<wbr></wbr> Do you want a website or an online store? Or maybe you are looking for a digital card? Whatever you are looking for feel free to contact me or just explore my website and take a look at my work. Welcome!  
                    </p>
                    <div className='mt-9'>
                        <div className='flex gap-x-1 lg:gap-x-4 lg:w-[60%]'>
                            <Button to={'/projects'} name={'Projects'}/>
                            <Button to={'/contact'} name={'Contact'}/>
                        </div>
                    </div>
                </section>
            </article>
        </main>
    );
}