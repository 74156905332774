import { Project } from "../components/project";
import nftPreview from "../images/nftPreview.png";
import ayaPrevision from "../images/ayaPrevision.png";
import w4a from "../images/w4a.png";

export default function Projects(){
    return(
        <main className="flex-1 flex items-center">
            <div className="w-full mt-9 lg:mt-16 xl:mt-18">
                <h1 className="text-center coda text-mainBlue text-2xl lg:text-3xl">Projects</h1>
                <section className="grid pt-[5.28rem] grid-col-3 gap-y-16 lg:pb-10 lg:grid-cols-6 lg:gap-x-10 lg:px-11 xl:px-32">
                    <Project 
                        title={'NFT Preview'} 
                        description={'A challenge from Frontend Mentor. It displays an NFT with it\'s creator and current price. Developed with the purpose of showcase my abilities with HTML, CSS and JS.'} 
                        imageURL={nftPreview} 
                        link={'https://www.ayaprevision.com'}
                    />
                    <Project 
                        title={'AyA Previsión'} 
                        description={'Capable of request insurances quotes, this landing page made AyA Previsión Positiva increase their coverance along all Mexico helping people protect their goods, families and their live.'} 
                        imageURL={ayaPrevision} 
                        link={'https://www.ayaprevision.com'}
                        classes={'col-start-6'}
                    />
                </section>
                <section className="grid pt-16 grid-col-3 gap-y-16 lg:pt-[5.28rem] lg:pb-10 lg:grid-cols-6 lg:gap-x-10 lg:px-11 xl:px-32">
                    <Project 
                        title={'W4A'} 
                        description={'Designed for a web development focused event. This page contained resources and the sign up for attendees. In collaboration with Microsoft Learn Student Ambassadors and MLSA - UNAM.'} 
                        imageURL={w4a} 
                        link={'https://w4a.lambda-club.com'}
                    />
                </section>
            </div>
        </main>
    );
}